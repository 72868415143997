<template>
    <!-- <ProgressBar mode="determinate" :value="progressBarValue * 100 / 3">{{ progressBarValue }}/3</ProgressBar> -->
    <Message :life=3000 :closable="true" @close="this.closedwarning" v-if="this.warnMessage.length > 0"
        severity="warn">{{ this.warnMessage }}</Message>
    <div class="arranger">
        <div class="toppanel">
            <p class="prompttext"
                v-if="(this.enableTouchEdits && ((this.productContact === undefined || this.productContact.length <= 0) || (this.productBrand === undefined || this.productBrand.length <= 0) || this.productName.length <= 0)) || this.runcounter > 0">
                Better Inputs = Better Ads</p>

            <p align="center" class="instructtext"
                v-if="(this.enableTouchEdits && (!(this.editbrand || this.editcontact || this.editproduct || this.editdesc || this.editimage || this.editlogo)))">

                Select <span class="genademph">any text or image</span> in the Ad below to customize.. When you are done
                editing, click button below </p>
            <!-- <p align="center" class="instructtext"
                v-if="(!this.enableTouchEdits && (!(this.editbrand || this.editcontact || this.editproduct || this.editdesc || this.editimage || this.editlogo)))">
                    
                You are all set. Click button below to generate Ads</p> -->




            <div v-if="this.editbrand" class=formcomp>
                <p class="Question">
                    {{ this.brandQuestion[this.productType] }}
                </p>
                <div class="inputchecker">
                    <InputText class="iptext" v-model="this.productBrand"
                        :placeholder="this.brandplaceholder[this.productType]" ref="brand" id="productBrand"
                        :invalid="this.productBrand.length > 25" @input="this.validate($event, this.productType == 'SocialMedia' ? 'Title' : 'Brand', this.productType == 'SocialMedia' ?  90 : 25)" />
                </div>
            </div>
            <div v-if="this.editproduct" class=formcomp>
                <p class="Question">
                    {{ this.nameQuestion[this.productType] }}
                </p>
                <div class="inputchecker">
                    <InputText class="iptext" v-model="this.productName"
                        :placeholder="this.nameplaceholder[this.productType]" ref="name" id="productName"
                        @input="this.validate($event, 'Product/Business name', 40)" @change="updateProduct(true)" />

                </div>


            </div>
            <!-- {{  (this.editcontact + " " + this.fetchSuccess + " " + !this.confirmcontact + " " + this.confirmbrand + " " + this.confirmproduct) + " " + !this.editimage + " " + !this.editbrand }} -->
            <div class=formcomp v-if="(this.editcontact)">
                <p class="Question"> Contact Info to be shown: </p>
                <div class="inputchecker">

                    <InputText v-model="productContact" id="productContactId" placeholder="Phone/Website/Email"
                        class="urltext" ref="productContactRef" @input="this.validate($event, 'Contact', 80)" />

                </div>


            </div>
            <div class="mediaformatlist"
                v-if="(this.editbrand || this.editproduct || this.editcontact) && !this.editdesc">
                <div v-for="category in ['Image Ads', 'Video Ads', 'Presentations']" :key="category.key">
                    <RadioButton v-model="mediaformat" :inputId="category" name="dynamic" :value="category" :disabled="(category == 'Image Ads' && productType == 'SocialMedia')" />
                    <label :for="category" class="formatlabel">{{ category.replace(" Ad", "") }}</label>
                </div>
            </div>

            <Button class="nextbutton" v-if="(this.editbrand || this.editproduct || this.editcontact) && !this.editdesc"
                @click="confirmBrandProduct">Next</Button>

            <div class=formcomp :style="{ width: '100%' }"
                v-if="(this.editdesc || ((this.fetchSuccess && !this.confirmcontact && this.confirmbrand && this.confirmproduct) && !this.editimage && !this.editbrand))">

                <p class="Question" :style="{ color: this.updatedDesc ? 'inherit' : 'red' }"> Edit the text below or tell
                    us more about your {{ this.productType == "SocialMedia" ?  "topic" : this.productType  }} </p>
                <div class="inputchecker">
                    <Textarea variant="filled" v-model="productDescription" id="productDescId" ref="productDescRef"
                        placeholder="Description..." class="bigtext" @change="handleDescChange"></Textarea>
                </div>
                <Button class="nextbutton" :disabled="!this.updatedDesc" @click="confirmContactDesc"> Next </Button>

            </div>




            <p class="Question"
                v-if="this.editimage || this.editlogo || (this.fetchSuccess && !this.confirmimage && this.confirmcontact && this.confirmbrand && this.confirmproduct && this.confirmdesc && this.confirmcontact)">
                Upload an  image and logo <span style="font-weight: 400;"> (Optional) </span> </p>
            <div class="inputchecker" id="imageform"
                v-if="this.invokingAI || this.editimage || this.editlogo || (this.fetchSuccess && !this.confirmimage && this.confirmcontact && this.confirmbrand && this.confirmproduct && this.confirmdesc && this.confirmcontact)">

                <div class="imcontainer">





                    <FileUpload mode="basic" :auto="true" id="uploadimagebutton" chooseLabel="Upload Image" name="image"
                        @select="selectImagefile($event)" :multiple="false" accept="image/jpeg,image/png"
                        :style="{ 'margin-top': '1vw' }" :maxFileSize="5000000" />


                    <!-- <FileUpload name="demo[]" url="https://byx64c7re4xufi2iuvsjwi2jme0fexpc.lambda-url.us-west-1.on.aws/" @upload="onTemplatedUpload($event)" :multiple="true"
                        accept="image/*" :maxFileSize="1000000" @select="onSelectedFiles">
                        <template #header="{ chooseCallback, uploadCallback, clearCallback, files }">
                            <div class="flex flex-wrap justify-between items-center flex-1 gap-4">
                                <div class="flex gap-2">
                                    <Button @click="chooseCallback()" icon="pi pi-images" rounded outlined
                                        severity="secondary"></Button>
                                    <Button @click="uploadEvent(uploadCallback)" icon="pi pi-cloud-upload" rounded
                                        outlined severity="success" :disabled="!files || files.length === 0"></Button>
                                    <Button @click="clearCallback()" icon="pi pi-times" rounded outlined
                                        severity="danger" :disabled="!files || files.length === 0"></Button>
                                </div>
                                <ProgressBar :value="totalSizePercent" :showValue="false"
                                    class="md:w-20rem h-1 w-full md:ml-auto">
                                    <span class="whitespace-nowrap">{{ totalSize }}B / 1Mb</span>
                                </ProgressBar>
                            </div>
                        </template>
<template #content="{ files, uploadedFiles, removeUploadedFileCallback, removeFileCallback }">
                            <div class="flex flex-col gap-8 pt-4">
                                <div v-if="files.length > 0">
                                    <h5>Pending</h5>
                                    <div class="flex flex-wrap gap-4">
                                        <div v-for="(file, index) of files" :key="file.name + file.type + file.size"
                                            class="p-8 rounded-border flex flex-col border border-surface items-center gap-4">
                                            <div>
                                                <img role="presentation" :alt="file.name" :src="file.objectURL"
                                                    width="100" height="50" />
                                            </div>
                                            <span
                                                class="font-semibold text-ellipsis max-w-60 whitespace-nowrap overflow-hidden">{{
        file.name }}</span>
                                            <div>{{ formatSize(file.size) }}</div>
                                            <Badge value="Pending" severity="warn" />
                                            <Button icon="pi pi-times"
                                                @click="onRemoveTemplatingFile(file, removeFileCallback, index)"
                                                outlined rounded severity="danger" />
                                        </div>
                                    </div>
                                </div>

                                <div v-if="uploadedFiles.length > 0">
                                    <h5>Completed</h5>
                                    <div class="flex flex-wrap gap-4">
                                        <div v-for="(file, index) of uploadedFiles"
                                            :key="file.name + file.type + file.size"
                                            class="p-8 rounded-border flex flex-col border border-surface items-center gap-4">
                                            <div>
                                                <img role="presentation" :alt="file.name" :src="file.objectURL"
                                                    width="100" height="50" />
                                            </div>
                                            <span
                                                class="font-semibold text-ellipsis max-w-60 whitespace-nowrap overflow-hidden">{{
        file.name }}</span>
                                            <div>{{ formatSize(file.size) }}</div>
                                            <Badge value="Completed" class="mt-4" severity="success" />
                                            <Button icon="pi pi-times" @click="removeUploadedFileCallback(index)"
                                                outlined rounded severity="danger" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
<template #empty>
                            <div class="flex items-center justify-center flex-col">
                                <i
                                    class="pi pi-cloud-upload !border-2 !rounded-full !p-8 !text-4xl !text-muted-color" />
                                <p class="mt-6 mb-0">Drag and drop files to here to upload.</p>
                            </div>
                        </template>
</FileUpload> -->


                </div>


                <div class="imcontainer">




                    <FileUpload mode="basic" :auto="true" name="logo" :style="{ 'margin-top': '1vw' }" id="logouploadbtn"
                        chooseLabel="Logo" @select="selectLogofile($event)" :multiple="false"
                        accept="image/jpeg,image/png" :maxFileSize="5000000" />
                </div>

                <Button class="nextbutton" :disable="this.invokingAI" @click="confirmImages"> {{ ((this.productImage
                    !== undefined &&
                    this.productImage !== null && this.productImage !== "") || (this.productLogo !== null &&
                        this.productLogo !==
                        undefined && this.productLogo !== "")) ? "Next" : "Next" }} </Button>

                <p v-if="this.invokingAI"> You are all set . Your content will generated now </p>
                <ProgressSpinner v-if="this.invokingAI" style="width: 20px;  height: 20px; z-index:8;"
                    strokeWidth="8" />


            </div>



            <Button class="nextbutton"
                v-if="this.enableTouchEdits && !(this.editbrand || this.editcontact || this.editdesc || this.editimage || this.editproduct)"
                @click="this.generate()">Generate Ads</Button>

        </div>




        <div v-if=this.enableTouchEdits class="bottompanel">
            <img class="adimage" :src="require('@/assets/sample.png')" alt="ad" />
            <img :src="overlayimage" class="overlayimage" />
            <div class="samplecontainer">
                <p class="brandtext"
                    @click="if (this.productValidated) { this.editbrand = true; this.editproduct = true; this.editdesc = false; this.editcontact = true; this.editimage = false; this.editlogo = false; }">
                    {{
                        this.productBrand == "" ? "Your Brand/Logo" : this.productBrand }}</p>
                <p class="producttext"
                    @click="if (this.productValidated) { this.editbrand = true; this.editproduct = true; this.editdesc = false; this.editcontact = true; this.editimage = false; this.editlogo = false; }">
                    {{
                        this.productName == "" ? "Your Product" : this.productName }}</p>
                <img id="logopreview" :src="this.productLogoURL" alt=""
                    @click="this.editimage = true; this.editlogo = true;">
                <img :src="require('@/assets/clear.png')" class="lclearimagebtn"
                    v-if="(this.productLogoURL != null && this.productLogoURL !== undefined && this.productLogoURL.length > 0) || this.productLogo != null"
                    @click="clearProductLogo" alt="Clear Image" />
                <img :src="require('@/assets/bluearrowclick.png')" id="touchgif" class="touchgif"
                    :style="{ left: '60px', top: '60px' }"
                    v-if="!(this.editbrand || this.editcontact || this.editproduct || this.editdesc || this.editimage || this.editlogo) && this.enableTouchEdits"
                    @click="if (this.productValidated) { this.editbrand = true; this.editproduct = true; this.editdesc = false; this.editcontact = true; this.editimage = false; this.editlogo = false; }" />
                <img v-if="this.sampleimage !== undefined && this.sampleimage.length > 0" :src="sampleimage"
                    class="samplegenimage" />
                <ProgressSpinner v-if="!this.fetchSuccess"
                    style="left:50%; top:50%; width: 20px;  height: 20px; margin-top:20px; z-index:8;"
                    strokeWidth="8" />
                <img :src="require('@/assets/bluearrowclick.png')" id="touchgif" class="touchgif"
                    :style="{ height: '60px', width: '60px', left: '150px', top: '250px', zIndex: 4 }"
                    v-if="(!(this.editbrand || this.editcontact || this.editproduct || this.editdesc || this.editimage || this.editlogo) && this.fetchSuccess && this.enableTouchEdits)"
                    @click="this.editimage = true; this.editlogo = true; this.editbrand = false; this.editcontact = false; this.editproduct = false; this.editdesc = false; this.amplitude.track('Edit Image clicked');" />
                <img id="uimagepreview" :src="this.productImageURL" alt=""
                    @click="this.editimage = true; this.editlogo = true; this.editbrand = false; this.editcontact = false; this.editproduct = false; this.editdesc = false; this.amplitude.track('Edit Image clicked');">
                <img :src="require('@/assets/clear.png')" class="pclearimagebtn"
                    v-if="(this.productImageURL != null && this.productImageURL !== undefined && this.productImageURL.length > 0) || this.productImage != null"
                    @click="clearProductImage" alt="Clear Image" />
                <div class="captiondiv" v-if="this.enableTouchEdits"
                    @click="this.editbrand = false; this.editproduct = false; this.editdesc = true; this.editcontact = true; this.editimage = false; this.editlogo = false; this.amplitude.track('Edit Caption clicked');">
                    <ProgressSpinner v-if="this.refreshcaption"
                        style="left:50%; top:50%; width: 20px;  height: 20px; margin-top:20px; z-index:5;"
                        strokeWidth="8" />
                    <img :src="require('@/assets/bluearrowclick.png')" class="touchgif"
                        :style="{ left: '60px', top: '120px' }"
                        v-if="(!(this.editbrand || this.editcontact || this.editproduct || this.editdesc || this.editimage || this.editlogo) && this.fetchSuccess && this.enableTouchEdits)"
                        @click="this.editbrand = false; this.editproduct = false; this.editdesc = true; this.editcontact = true; this.editimage = false; this.editlogo = false; this.amplitude.track('Edit Caption clicked');" />
                </div> 9
                <div class="calloutdiv" v-if="this.enableTouchEdits"
                    @click="this.editbrand = false; this.editproduct = false; this.editdesc = true; this.editcontact = true; this.editimage = false; this.editlogo = false; this.amplitude.track('Edit CTA clicked');">
                    <img :src="require('@/assets/bluearrowclick.png')" class="touchgif"
                        :style="{ right: '60px', top: '60px' }"
                        v-if="(!(this.editbrand || this.editcontact || this.editproduct || this.editdesc || this.editimage || this.editlogo) && this.fetchSuccess && this.enableTouchEdits)"
                        @click="this.editbrand = false; this.editproduct = false; this.editdesc = true; this.editcontact = true; this.editimage = false; this.editlogo = false; this.amplitude.track('Edit CTA clicked');" />
                </div>
                <div class="imagediv" v-if="this.enableTouchEdits"
                    @click="this.editimage = true; this.editlogo = true; this.editbrand = false; this.editcontact = false; this.editproduct = false; this.editdesc = false; this.amplitude.track('Edit Image clicked');">
                </div>
            </div>
        </div>

        <!-- <img :src="require('@/assets/yellowarrowright.png')" class="brandtouch" /> -->
        <!-- <p class="contacttext">{{ this.productContact == "" ? "Contact/Website Info" : this.productContact }}</p> -->

    </div>







</template>

<script>
// import * as amplitude from '@amplitude/analytics-browser';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Textarea from 'primevue/textarea';
import RadioButton from 'primevue/radiobutton';
// import ProgressBar from 'primevue/progressbar';
// import Badge from 'primevue/badge';
import FileUpload from 'primevue/fileupload';
import ProgressSpinner from 'primevue/progressspinner';
import * as amplitude from '@amplitude/analytics-browser';
import Message from 'primevue/message';
import { ref } from "vue";




export default {
    name: 'PreviewAd',
    props: ["productText", "productIdInput", "fromVideoTab", "runcounter", "mediaFormatPreference"],
    data() {
        return {

            progressBarValue: 1,
            productType: "Product",
            question: "What do you need this content for ?",
            productBrand: "",
            productName: "",
            productURL: "",
            productLogo: "",
            productDescription: "",
            productValues: "",
            productImage: "",
            productId: "",
            productImageURL: null,
            productLogoURL: null,
            productContact: "",
            brandlabel: "Brand",
            namelabel: "Name",
            sitelabel: "www.myproduct.com",
            sellinglabel: "What do you think are key selling points?",
            inProgress: false,
            warnMessage: "",
            canGoForward: false,
            productContactRef: ref(),
            productDescRef: ref(),
            sampleimage: "",
            cta: "",
            ctaFontSize: 22,
            descFontSize: 14,
            captionFontSize: 18,
            caption: "",
            desc: "",
            editbrand: false,
            editproduct: false,
            editcontact: false,
            editdesc: false,
            editimage: false,
            editlogo: false,
            confirmbrand: false,
            confirmproduct: false,
            confirmcontact: false,
            confirmimage: false,
            confirmdesc: false,
            fetchSuccess: false,
            overlayimage: require('@/assets/preoverlay.png'),
            refreshcaption: false,
            amplitude: null,
            showlogo: false,
            productValidated: false,
            enableTouchEdits: false,
            invokingAI: false,
            llmDescription: "",
            updatedDesc: false,
            files: [],
            uploadedFiles: [],
            totalSizePercent: 0,
            mediaformat: "Image Ads",
            brandQuestion: {
                "Product": "What is your brand name?",
                "Service": "What is your business name?",
                "SocialMedia": "What is the title of this video/presentation?"
            },
            nameQuestion: {
                "Product": "What is your product?",
                "Service": "What service do you offer?",
                "SocialMedia": "What topic is to be covered in the video/presentation?"
            },
            brandplaceholder: {
                "Product": "eg: Apple",
                "Service": "eg: ABC Inc",
                "SocialMedia": "eg: 5 Secrets to professional success"
            },
            nameplaceholder: {
                "Product": "eg: iPhone 12",
                "Service": "eg: Plumbing service",
                "SocialMedia": "eg: How to make a cake"
            }





        }
    },
    emits: ['onProductId', "onFirstRun"],
    computed: {

    },
    methods: {

        chooseCallback() {
            console.log("Choose Callback")

        },
        uploadCallback() {
            console.log("upload Callback")

        },
        clearCallback() {
            console.log("clear Callback")

        },
        removeUploadedFileCallback(index) {
            console.log("remove Uploaded File Callback", index)

        },
        removeFileCallback(index) {
            console.log("remove File Callback", index)

        },
        onTemplatedUpload(event) {
            console.log("onTemplatedUpload", event)

        },
        onSelectedFiles(event) {
            console.log("onSelectedFiles", event)

        },
        formatSize(size) {
            return size
        },
        onRemoveTemplatingFile(file, removeFileCallback, index) {
            console.log("onRemoveTemplatingFile", file, removeFileCallback, index)

        },
        uploadEvent(uploadCallback) {
            console.log("uploadEvent", uploadCallback)

        },



        selectImagefile(events) {
            console.log(events)

            this.confirmimage = true
            amplitude.track('UploadedImage', { "productName": this.productName });
            const imageElement = document.querySelector('#uimagepreview');
            const file = events.files[0];
            if (imageElement != null) {
                imageElement.src = URL.createObjectURL(file);
                imageElement.style.display = "block";
                imageElement.style.zIndex = "10";
            }// get the file from the event
            this.encodeFileAsBase64(file)
                .then(base64File => {
                    this.productImage = base64File;

                })
                .catch(error => {
                    console.error(error);
                });
        },
        selectLogofile(events) {
            this.confirmimage = true
            amplitude.track('UploadedLogo', { "productName": this.productName });
            const imageElement = document.querySelector('#logopreview');
            const file = events.files[0];
            if (imageElement != null) {
                imageElement.src = URL.createObjectURL(file);
                imageElement.style.display = "block";
                imageElement.style.zIndex = "10";
                this.showlogo = true;
            }
            // get the file from the event
            this.encodeFileAsBase64(file)
                .then(base64File => {
                    this.productLogo = base64File;
                })
                .catch(error => {
                    console.error(error);
                });
        },

        encodeFileAsBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = function () {
                    resolve(reader.result);
                };
                reader.onerror = reject;
                reader.readAsDataURL(file);
            });
        },
        closedwarning() {
            this.warnMessage = "";
        },

        processData(data) {
            console.log("Data", data);
            this.productId = data.product.productId;
            console.log("ProductId is", this.productId);
            console.log("emitting productId", this.productId)
            this.$emit('onProductId', this.productId, this.productBrand + " " + this.productName, this.productType, this.productImage, this.mediaformat == "Presentation Ads" ? "Presentations" : this.mediaformat,this.productDescription)

        },


        async updateProduct(descupdate = false) {
            var payload = {
                previewImage: this.sampleimage,
                previewDesc: this.desc,
                previewCaption: this.caption,
                productId: this.productId,
                previewCTA: this.cta,
                productValidated: this.productValidated,
                productContact: this.productContact,
                productBrand: this.productBrand,
                productName: this.productName,
                ManualInput: true
            };
            if (descupdate) {
                payload.descUpdateNeeded = true
            }

            try {
                var response = await fetch('https://wtg5xjz2tl.execute-api.us-west-1.amazonaws.com/v1/products/productInfo', {
                    method: "POST",
                    body: JSON.stringify(payload),
                    headers: { 'Content-Type': 'application/json' }
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                var resp = await response.json();
                console.log("Response is", resp);
                this.productDescription = resp.product.productDescription;
            } catch (error) {
                console.error('There was a problem with the fetch operation:', error);
            }
        },

        clearProductImage() {
            this.productImageURL = ""; // Assuming you want to clear the image URL
            this.productImage = null;

            const imageElement = document.querySelector('#uimagepreview');
            if (imageElement) {
                imageElement.style.zIndex = '-1'; // Correct the property to 'zIndex'
            }
        },
        clearProductLogo() {
            this.productLogoURL = ""; // Assuming you want to clear the image URL
            this.productLogo = null;
            const imageElement = document.querySelector('#logopreview');
            if (imageElement) {
                imageElement.style.zIndex = '-1'; // Correct the property to 'zIndex'
            }
        },

        getPreviewLayout() {
            console.log("Getting Preview Layout", this.caption, this.desc, this.cta)
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            this.refreshcaption = true;

            const raw = JSON.stringify({
                "caption": this.caption,
                "description": this.desc,
                "cta": this.cta,
                "contact": this.productContact
            });

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            };

            fetch("https://7lmjeao27hnlyvg5k6iawnagjq0yntir.lambda-url.us-west-1.on.aws/", requestOptions)
                .then((response) => response.json())
                .then((result) => this.processPreviewImage(result))
                .catch((error) => console.error(error));
            this.updateProduct()
        }
        ,

        processPreviewImage(imagedata) {
            if (imagedata.image !== undefined && imagedata.image !== null && imagedata.image.length > 0)
                this.overlayimage = imagedata.image;
            this.fetchSuccess = true;
            this.refreshcaption = false;

        },
        adjustSampleContainerSize() {
            if (!this.enableTouchEdits) { return }
            const imageElement = document.querySelector('.adimage'); // Adjust selector as needed
            console.log("Image Element is", imageElement)

            const aspectRatio = 9 / 16;

            // Now adjust the .samplecontainer size based on the image's dimensions or aspect ratio
            // This example directly sets the width and height, but you might adjust based on aspect ratio
            const sampleContainer = document.querySelector('.samplecontainer');
            console.log("Sample Container is", sampleContainer)

            // if ( imageElement == null || sampleContainer == null || imageElement === undefined || sampleContainer === undefined ){
            //     return
            // }

            if (imageElement.width / imageElement.height >= aspectRatio) {
                sampleContainer.width = imageElement.height * aspectRatio;
                sampleContainer.style.width = `${imageElement.height * aspectRatio}px`;
                sampleContainer.height = imageElement.height;
                sampleContainer.style.height = `${imageElement.height}px`;
                sampleContainer.style.left = `${(imageElement.width - imageElement.height * aspectRatio) / 2}px`;
                if (window.innerWidth <= 768) {
                    sampleContainer.style.top = "0px";
                }
            }
            else {
                sampleContainer.width = imageElement.width;
                sampleContainer.style.width = `${imageElement.width}px`;
                sampleContainer.height = imageElement.width / aspectRatio;
                sampleContainer.style.height = `${imageElement.width / aspectRatio}px`;
                if (window.innerWidth > 768) {
                    sampleContainer.style.top = `${(imageElement.height - imageElement.width / aspectRatio) / 2}px`;
                }
                else {
                    sampleContainer.style.left = "0px";
                }
            }
            // sampleContainer.style.height = `${imageElement.height}px`;

            // Log for debugging
        }

        ,

        getDescStyle() {

            return { height: "70%" };
            // if (this.enableTouchEdits) {
            //     return {width:"100%"}


            // }
            // else {
            //     return { height: '25vh'}
            // }
        },


        validate(event, field, length) {
            if (event.target.value.length > length) {
                this.warnMessage = field + " should be less than " + length + " characters";
                if (field == "Contact") { event.target.value = event.target.value.substring(0, length) }
                else {
                    event.target.value = this.titleCase(event.target.value.substring(0, length));
                }
            }
            else {
                if (field != "Contact") {
                    event.target.value = this.titleCase(event.target.value);
                }
            }

            if (field == "Brand") {
                this.productBrand = event.target.value;
            }
            if (field == "Product/Business name") {
                this.productName = event.target.value;
                console.log("Product Name entered  is", this.productName)
            }
            if (field == "Contact") {
                this.productContact = event.target.value;
            }
            if (this.checkMandatoryFields()) {
                this.canGoForward = true;
            }

        },
        titleCase(str) {
            if (str.charAt(0) == str.charAt(0).toLowerCase()) {

                return str.toLowerCase().split(' ').map(function (word) {
                    return (word.charAt(0).toUpperCase() + word.slice(1));
                }).join(' ');
            }
            else { return str; }

        }



        ,

        checkMandatoryFields() {
            if (this.productBrand === undefined) {
                this.productBrand = ""
            }
            if (this.productName === undefined) {
                this.productName = ""
            }
            if (this.progressBarValue == 1) {
                if (this.productBrand.length == 0 || this.productName.length == 0) {
                    return false
                }

            }


            return true
        },
        goForward() {
            amplitude.track('NextPressed', { "productName": this.productName, "progress": this.progressBarValue });
            if (this.progressBarValue == 3) {
                amplitude.track('NextPressed', { "contact": this.productContact, "progress": this.progressBarValue });
                this.generate();
            }
            else if (this.progressBarValue == 2) {
                this.progressBarValue += 1;
                amplitude.track('NextPressed', { "description": this.productDescription, "progress": this.progressBarValue });
                this.$nextTick(function () {
                    console.log(this.$refs.productContactRef.$el.focus());
                })

            }
            else if (this.progressBarValue == 1) {
                this.progressBarValue += 1;
                this.$nextTick(function () {
                    console.log(this.$refs.productDescRef.$el.focus());
                })

            }
            else {
                this.progressBarValue += 1;
            }

        },

        confirmBrandProduct() {
            this.editbrand = false;
            this.editproduct = false;
            this.confirmbrand = true;
            this.confirmproduct = true;
            this.confirmcontact = true;
            amplitude.track('brand verified', { 'brand': this.productBrand });
            amplitude.track('product verified', { 'product': this.productName });
            amplitude.track('Contact  Confirmed', { 'contact': this.productContact });

            if (!this.confirmdesc) {
                this.editdesc = true;
                if (!this.enableTouchEdits) {
                    this.$nextTick(() => {
                        var descfield = document.querySelector('#productDescId');
                        if (descfield != null && descfield !== undefined) {
                            descfield.style.height = "25vh";
                            if (this.llmDescription != this.productDescription) {


                                descfield.style.border = "2px solid red";
                            }
                            else {
                                descfield.style.border = "2px solid green";
                            }

                        }
                        console.log("EDIT DESC IS", this.editdesc)
                    });

                }

            }
            if ((this.confirmbrand && this.confirmproduct && this.confirmcontact && this.confirmimage && this.confirmdesc) && !this.productValidated) {
                this.productValidated = true;

            }

            if (this.mediaformat == "Image Ads") {
                this.getPreviewImage(true);
            }
            this.editcontact = false;

        },
        confirmContactDesc() {
            this.getPreviewImage(true);
            this.confirmcontact = true;
            this.confirmdesc = true;
            amplitude.track('Description Confirmed', { 'description': this.productDescription });
            this.editdesc = false;
            if (!this.confirmimage) {
                this.editimage = true
                if (!this.enableTouchEdits) {
                    this.$nextTick(() => {
                        document.querySelector('#imageform').style.flexDirection = "column";
                        document.querySelector('#imageform').style.rowGap = "3vh";
                    });

                }
            }

            if ((this.confirmbrand && this.confirmproduct && this.confirmcontact && this.confirmimage && this.confirmdesc) && !this.productValidated) {
                this.productValidated = true;

            }
            console.log(this.productContact)
            this.updateProduct();

        },
        handleDescChange() {

            var descfield = document.querySelector('#productDescId');
            if (descfield != null && descfield !== undefined) {

                if (this.llmDescription == this.productDescription) {
                    descfield.style.border = "3px solid red";
                    descfield.style.boxShadow = "inset 1 1 10px red";
                    this.updatedDesc = false;
                }
                else {
                    this.updatedDesc = true;
                    descfield.style.border = "3px solid green";
                    descfield.style.boxShadow = "inset 1 1 10px green";
                }
            }


        },
        confirmImages() {
            this.editimage = false;
            this.editlogo = false;
            this.confirmimage = true;
            amplitude.track('Image and Logo Confirmed', {})

            if ((this.confirmbrand && this.confirmproduct && this.confirmcontact && this.confirmimage && this.confirmdesc) && !this.productValidated) {
                this.productValidated = true;

            }
            this.invokingAI = true;
            this.generate();

        },

        generate() {
            this.inProgress = true;
            amplitude.track('GeneratePressed', { "productName": this.productName });
            this.question = "Generating your Content"
            var payload = {
                productType: this.productType,
                productBrand: this.productBrand,
                productName: this.productName,
                productURL: this.productURL,
                productLogo: this.productLogo,
                productDescription: this.productDescription,
                productValues: this.productValues,
                productImage: this.productImage,
                productContact: this.productContact,
                bestImageURL: this.productImageURL,
                logoURL: this.productLogoURL,
                productId: this.productId,
                productValidated: this.productValidated,
                ManualInput: true
            }



            fetch('https://wtg5xjz2tl.execute-api.us-west-1.amazonaws.com/v1/products/productInfo', { method: "POST", body: JSON.stringify(payload), headers: { 'Content-Type': 'application/json' } }).then(response => response.json()).then(data => this.processData(data));

        },
        getProductInfo(productId) {
            amplitude.track("Input form showed", { "productName": this.productName, "productId": productId })
            var payload = { productInput: productId }
            fetch('https://wtg5xjz2tl.execute-api.us-west-1.amazonaws.com/v1/products/productInfo', { method: "POST", body: JSON.stringify(payload), headers: { 'Content-Type': 'application/json' } }).then(response => response.json()).then(data => this.populate(data));
        },

        async getPreviewImage(onlycaption = false) {
            var url = ""
            if (onlycaption) {
                // this.editdesc=false;
                // this.editcontact=false;
                this.refreshcaption = true;
                var payload = {
                    productDescription: this.productDescription,
                    productId: this.productId,
                    productContact: this.productContact,
                    ManualInput: true
                }
                url = "https://utu7suvdrtyfsiixzpa6juzbtq0umaks.lambda-url.us-west-1.on.aws/?imageOnly&captionOnly&product=" + this.productId
                var response = fetch('https://wtg5xjz2tl.execute-api.us-west-1.amazonaws.com/v1/products/productInfo', { method: "POST", body: JSON.stringify(payload), headers: { 'Content-Type': 'application/json' } })
                var resp = await response
                console.log("Response is", resp)





            }
            else {
                url = "https://utu7suvdrtyfsiixzpa6juzbtq0umaks.lambda-url.us-west-1.on.aws/?imageOnly&product=" + this.productId
            }
            const FETCH_TIMEOUT = 175000; // Set timeout in milliseconds
            let didTimeOut = false;

            new Promise((resolve, reject) => { // Use arrow function here
                const timeout = setTimeout(() => { // Also arrow function here
                    didTimeOut = true;
                    reject(new Error('Request timed out'));
                }, FETCH_TIMEOUT);

                fetch(url, { method: "GET" })
                    .then(response => response.json())
                    .then(data => {
                        clearTimeout(timeout);
                        if (!didTimeOut) {
                            this.processImage(data);
                            resolve(data);
                        }
                    })
                    .catch(err => {
                        console.error('Fetch error:', err);
                        clearTimeout(timeout);
                        if (!didTimeOut) reject(err);
                    });
            })
                .catch(err => {
                    console.error('Promise error:', err);
                });
        },

        processImage(data) {
            if (data.image !== undefined && data.image !== null && data.image.length > 0) {
                this.sampleimage = data.image
            }
            this.desc = data.description
            this.cta = data.cta
            // this.productContact = data.cta
            this.caption = data.caption
            this.getPreviewLayout()
            this.adjustSampleContainerSize();

            //   if (data != null && data.image.length > 0) {
            //     return data.image
            //   }
        },

        populate(data) {

            this.productType = data.product.productType;
            this.productBrand = data.product.productBrand;
            this.productName = data.product.productCategory;
            this.productURL = data.product.productURL;
            this.productLogo = data.product.productLogo;
            this.productDescription = data.product.productDescription.replace(".", ".\n");
            this.llmDescription = data.product.llmDescription;
            this.productValues = data.product.features;
            this.productValidated = data.product.productValidated;
            this.productImage = data.product.productImage;
            this.productContact = data.product.productContact;
            this.productImageURL = data.product.bestImageURL;
            this.productLogoURL = data.product.logoURL;
            this.sampleimage = data.product.previewImage;
            this.desc = data.product.previewDesc;
            this.cta = data.product.previewCTA;
            this.caption = data.product.previewCaption;
            this.updatedDesc = (data.product.llmDescription == data.product.productDescription)




            if (this.fromVideoTab) {
                this.productValidated = false;
                this.enableTouchEdits = false;
            }
            if (this.productValidated) {
                this.enableTouchEdits = true;
                console.log("Touch edit is true")
            }
            else {
                this.$emit('onFirstRun', true)
                this.fetchSuccess = true;
                this.refreshcaption = true;
            }
            // this.canGoForward = this.checkMandatoryFields()
            console.log("touch edit", this.enableTouchEdits, this.sampleimage)
            if (this.enableTouchEdits && (this.sampleimage == null || this.sampleimage.length == 0 || this.sampleimage === undefined)) {
                console.log("Getting Preview Image")
                this.getPreviewImage();
            }
            if (!this.enableTouchEdits) {
                this.fetchSuccess = true;



            }

            this.$nextTick(function () {
                this.adjustSampleContainerSize();
            })


            this.refreshcaption = false;
            if (this.cta !== undefined && this.cta.length > 0 && this.enableTouchEdits) {
                this.getPreviewLayout();
            }



            var imageElement = null;
            if (this.productLogoURL !== undefined && this.productLogoURL !== null && this.productLogoURL.length > 0) {
                imageElement = document.querySelector('#logopreview');
                if (imageElement != null) {
                    imageElement.src = this.productLogoURL;
                    imageElement.style.zIndex = "10";
                    imageElement.style.display = "block";
                }

            }
            if (this.productImageURL !== undefined && this.productImageURL !== null && this.productImageURL.length > 0) {
                imageElement = document.querySelector('#uimagepreview');
                if (imageElement != null) {
                    imageElement.src = this.productImageURL;
                    imageElement.style.zIndex = "10";
                    imageElement.style.display = "block";
                }
            }
            console.log("previewImage is ", this.sampleimage)



            if (this.productValidated) {
                this.confirmbrand = true;
                this.confirmproduct = true;
                this.confirmimage = true;
                this.confirmcontact = true;
                this.confirmdesc = true;

            }
            else {
                this.editbrand = true;
                this.editproduct = true;
                this.editcontact = true;
            }

            if (!this.productValidated || (this.productBrand.length <= 0) || this.productName.length <= 0) {
                this.editbrand = true;
                this.editproduct = true;
                this.editcontact = true;
            }
            if ((this.productContact === undefined || this.productContact.length <= 0) && !this.editbrand && !this.editproduct) {
                this.editcontact = true;
                this.confirmcontact = false;
            }





        }


    },
    watch: {
        productDescription(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.handleDescChange();
            }
        },

        mediaformat(newVal, oldVal) {
            console.log(`Media format changed from ${oldVal} to ${newVal}`);
            amplitude.track('Media format chosen', { 'format': newVal })
        }
    },

    mounted() {
        this.amplitude = amplitude;
        this.mediaformat = this.mediaFormatPreference;
        amplitude.track('Showed Manual Entry', { "productName": this.productName, "productId": this.productIdInput });
        if (this.productIdInput.length == 36) {
            this.productId = this.productIdInput;
            console.log("product id in input form is ", this.productIdInput)
            this.getProductInfo(this.productIdInput)

        }
        console.log("VIdeo Tab is", this.fromVideoTab)
        // if (this.fromVideoTab){
        //     this.productValidated=false;
        //     this.enableTouchEdits=true;
        // }



        // this.adjustCtaFontSize();
        // If parent size can change, consider adding an event listener or ResizeObserver
        window.addEventListener('resize', this.adjustSampleContainerSize);
        //  window.addEventListener('resize', this.adjustDescFontSize);
        //  window.addEventListener('resize', this.adjustCaptionFontSize);


        // this.productName= this.productText;




    },

    beforeUnmount() {
        // Clean up the event listener when the component is destroyed
        window.removeEventListener('resize', this.adjustSampleContainerSize);
        // window.removeEventListener('resize', this.adjustDescFontSize);
        // window.removeEventListener('resize', this.adjustCaptionFontSize);
    },

    components: {
        // InputText, Button, Textarea, RadioButton, FileUpload, Message
        InputText, Message, ProgressSpinner, Textarea, FileUpload, Button, RadioButton //,Badge,ProgressBar

    }
}
</script>

<style scoped>
.inputform {
    padding-top: 1vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center-start;
    padding-bottom: 0px;
    margin-bottom: 0px;
}

.formcomp {
    padding-top: 1%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    height: fit-content
}


.imagecomp {
    padding-top: 0vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    padding-bottom: 0px;
    margin-bottom: 0px;
    height: fit-content
}

.producttype {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 2vw;

}

.instructtext {
    position: relative;
    font-size: 18px;
    margin-top: 1vw;
    font-family: "Raleway", sans-serif;
    font-weight: 400;

}

.brandcategory {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 2vw;

}



.Question {
    margin-top: 10px;
    margin-bottom: 10px;
}

.captiondiv {
    position: absolute;
    width: 100%;
    height: 18%;
    top: 65%;
    left: 0px;
    z-index: 5;
    cursor: pointer;
}

.calloutdiv {
    position: absolute;
    width: 100%;
    height: 10%;
    top: 85%;
    left: 0px;
    z-index: 5;
    cursor: pointer;
}

.imagediv {
    position: absolute;
    width: 100%;
    height: 50%;
    top: 15%;
    left: 0px;
    z-index: 5;
    cursor: pointer;
}



.iptext,
.urltext {
    width: 90%;
    height: 32px;
    /* background: #ede8e8;
    color: #000; */
    border-radius: 10px;

}

.rightpanel img {
    border-radius: 8px;
}



.bigtext {
    width: 90%;
    height: 10vh;
    background: #ede8e8;
}

.arranger {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: auto;


}

.prompttext {
    text-align: center;
    color: orange;
    font-style: italic;
    font-size: larger;
}

.urltext {
    width: 30vw;

}

.question {
    font-size: 1.3vw;
    font-weight: medium;
    align-self: flex-start;
}

.mediaformatlist {
    margin-top: 20px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 2vw;
    order: 2;
}

.formatlabel {

    margin-left: 1vw;

}

button.nextbutton {
    margin-top: 2vw;
    align-self: center;
    /* justify-self: ; */
    background: #F3610F;
    color: #FFF;
    border: none;
    padding: 7px 25px;
    border-radius: 10px;
    font-weight: 500;
    order: 2;
}

button.backbutton {
    margin-top: 2vw;
    margin-bottom: 2vw;
    align-self: center;
    justify-self: flex-end;
    background: transparent;
    border: #FFF solid 2px;
    color: #FFF;
    padding: 7px 25px;
    border-radius: 10px;
    font-weight: 500;
}

.buttonsdiv {
    margin-top: 3vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 4vw;

}

.prevbutton {
    margin-top: 1vw;
    margin-bottom: 2vw;
    align-self: left;
    justify-self: flex-end;
}



.checkerbutton {
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.checkerbutton {
    background-image: url('../assets/checker.png');
    /* Image on default */
    transition: background-image 0.5s ease-in-out;
    /* Optional: Adds transition effect */
}

.checkerbutton:hover {
    background-image: url('../assets/checkerhover.png');
    /* Image on hover */
}

.prodcutdescvals {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    height: 4vw;

}

@keyframes blink-animation {

    0%,
    100% {
        opacity: 0
    }

    50% {
        opacity: 1;
    }

}

@keyframes move-up-animation {
    0% {
        transform: translateY(0);
        opacity: 1;
    }

    33% {
        opacity: 1;
    }

    66% {
        opacity: 1;
    }

    100% {
        transform: translateY(-20px);
        /* Example transformation */
        opacity: 0;
        /* Make the element disappear */
    }
}

@keyframes move-right-animation {

    0%,
    100% {
        transform: translateX(0);
        opacity: 0;
    }

    50% {
        transform: translateX(20px);
        opacity: 1;
    }
}




.imcontainer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    position: relative;
}

.imageref {
    width: 50px;
    height: 50px;
    margin-right: 2rem;
    position: relative;
}



.rightpanel {
    width: 38%;

    position: relative;
    height: 80%;


}

.skipbutton {
    border-radius: 10px;
    background: #3365EA00;
    color: #FFF;
    border: none;
    font-weight: 500;
    align-self: top;
    justify-self: flex-end;

}


.brandtext {
    position: absolute;
    font-size: 16px;
    font-weight: bold;
    top: 2%;
    left: 5%;
    z-index: 3;
}

.brandtouch {
    position: absolute;
    height: 50px;
    width: 50px;

    z-index: 4;
}

#logopreview {
    width: 50px;
    height: 50px;
    margin-right: 1rem;
    position: absolute;
    z-index: -1;
    right: 2%;
    top: 2%;
    object-fit: contain;
    filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.4));
    cursor: pointer;
    display: none;
}

#uimagepreview {
    width: 150px;
    height: 150px;
    margin-right: 1rem;
    position: absolute;
    object-fit: contain;
    z-index: -1;
    right: 2%;
    display: none;
    top: 45%;
    right: 2%;
    filter: drop-shadow(5px 10px 10px rgba(245, 3, 3, 0.9));
    border-radius: 5%;
    cursor: pointer;

}

.touchgif {
    position: absolute;
    height: 50px;
    width: 50px;
    animation: move-up-animation 4s;
    animation-iteration-count: 3;
    z-index: 7;
    filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2));
}

.pclearimagebtn {
    position: absolute;
    top: 46%;
    z-index: 100;
    right: 8%;
    height: 20px;
    width: 20px;
    cursor: pointer;
    filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.3));

}

.lclearimagebtn {
    position: absolute;
    right: 2%;
    top: 2%;
    z-index: 100;
    height: 20px;
    width: 20px;
    cursor: pointer;
    filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.3));

}

.producttext {
    position: absolute;
    font-size: 14px;
    font-weight: medium;
    top: 6%;
    left: 5%;

    z-index: 3;
}

.contacttext {
    position: absolute;
    font-size: 12px;
    font-weight: bold;
    top: 2%;
    right: 11%;
    z-index: 3;
}





.adimage,
.overlayimage {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    object-fit: contain;
    border-radius: 10px;
}

.samplecontainer {
    position: absolute;
    width: 100%;
    height: 100%;
    aspect-ratio: 9 / 16;
    top: 0px;
    left: 0px;
}


.samplegenimage {
    position: absolute;
    width: 100%;
    aspect-ratio: 1;
    top: 15%;
    /* margin-left:10%; */
    margin-right: 10%;
    object-fit: contain;
    cursor: pointer;
}

.bottompanel {
    position: relative;
    height: 60vh;
    width: 100%;
    margin-bottom: 10px;
    /* height: 40vw; */


}

.genademph {
    font-size: 18px;
    font-weight: bold;
    color: #F3610F;
    margin-top: 1vw;
    font-family: "Raleway", sans-serif;
    font-weight: 400;

}

.toppanel {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 20px;
    /* align-items: center; */
}


.inputchecker {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    column-gap: 10px;
    width: 100%;
}

/* Adjust z-index if needed */
.adimage {
    z-index: 2;
}

.overlayimage {
    z-index: 3;
    /* This will be on top of .adimage */
}


@media(max-width:1700px) {}



@media(max-width:1400px) {
    .brandtext {
        font-size: 14px;
    }

    .producttext {
        font-size: 12px;
    }

    .contacttext {
        font-size: 10px;
        margin-left: 12vw;
    }



}

@media(max-width:1200px) {
    .brandtext {
        font-size: 12px;
    }

    .producttext {
        font-size: 10px;
    }

    .contacttext {
        font-size: 9px;
        margin-left: 10vw;
    }



}


@media(max-width:1000px) {
    .brandtext {
        font-size: 10px;
    }

    .producttext {
        font-size: 8px;
    }

    .contacttext {
        font-size: 6px;
        margin-left: 13vw;
    }






}

@media(max-width:768px) {
    .brandtext {
        font-size: 16px;
    }

    .producttext {
        font-size: 14px;
    }

    .contacttext {
        font-size: 12px;

    }

    .toppanel {
        flex-direction: column;
    }

    .rightpanel {
        order: 2
    }

    .inputform {
        order: 1;
        width: 100%;
    }

    .formcomp {
        order: 1;
        width: 100%;
    }





}
</style>
