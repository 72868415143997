<template>



    <div class="video-container" :style="{ marginTop: '15px' }">
        <video v-if="this.type == 'video' && this.pptxurl == ''" crossorigin="anonymous" preload="metadata" ref="videoplayer" id="video"
            :class="['videoplayer', { 'rainbow-spin': isRainbowSpin }]" width="80%" controls @contextmenu.prevent
            controlsList="nodownload noplaybackrate nopictureinpicture "
            @pause="this.$emit('videopaused', this.$refs.videoplayer.currentTime)"
            @play="this.$emit('videoplayed', this.$refs.videoplayer.currentTime)"
            @ended="this.$emit('videoended', this.$refs.videoplayer.currentTime)"
            @seeked="this.$emit('videoseeked', this.$refs.videoplayer.currentTime)"
            @loadeddata="this.$emit('videoloaded', this.$refs.videoplayer.currentTime)"
            @keydown="handleKeydown($event)">
            <source :src="`
  https://sdgenvideos.s3.us-west-1.amazonaws.com/videos/${this.videoid}.mp4#t=0.2`" type="video/mp4">

            Your browser does not support the video tag.
        </video>
        <iframe v-if="this.type == 'pptx' || this.pptxurl != ''" :src="'https://view.officeapps.live.com/op/embed.aspx?src=' + pptxurl"
            :class="['pptplayer', { 'rainbow-spin': isRainbowSpin }]" width="80%" allowfullscreen></iframe>
        <img ref="answerimage"
            :class="['imageoverlay', { visible: this.showanswerimage, hidden: !this.showanswerimage }]"
            :src="this.answerimage" alt="answer" @load="onAnswerImageLoad" />

    </div>
    <div class="searchgroup" v-if="this.videoid.includes('SPTMP-36S-DOC-2') || this.videoid.includes('SPTMP-DOC-SLIDES-1') || !this.videoid.includes('SPTMP')">
        <img class="info" src="../assets/infoicon.png"
            v-tooltip="'When you share this video, users can ask questions about this topic at anytime'" />
        <InputText class="queryinput" placeholder="Ask questions about this topic..." type="text"
            :disabled="qinprogress" @keyup.enter="this.queryVideo()" v-model="querytext" variant="filled" />
        <Button icon="pi pi-search" class="searchbtn" @click="this.queryVideo()" />
        <!-- <img ref="chapterbtn" title="Chapters" class="nonquerybutton" src="../assets/chaptericon.png" @click="this.showChapters = true" /> -->
    </div>













</template>

<script>
import * as amplitude from '@amplitude/analytics-browser';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';

// import InputGroup from 'primevue/inputgroup';
// import InputGroupAddon from 'primevue/inputgroupaddon';








export default {
    name: 'IQFrame',

    components: { Button, InputText },
    emits: ['videopaused', 'videoplayed', 'videoended', 'videoseeked'],
    props: ["videoid", "type"],
    watch: {
        videoid: function (newVal, oldVal) {
            console.log("videoid changed", newVal, oldVal, this.type);
            this.getSceneData();
            //refresh video in videoplayer
            if (this.type == "video" && this.videoid.includes('SPTMP')) {
                this.pptxurl = "";
                this.$nextTick(() => {
                    if (this.$refs.videoplayer) {

                        this.$refs.videoplayer.src = `https://sdgenvideos.s3.us-west-1.amazonaws.com/videos/${this.videoid}.mp4#t=0.2`;
                    }
                }
                );
            }

            if (this.type == "pptx" && !this.videoid.includes('SPTMP')) {
                this.pptxurl = `https://sdgenvideos.s3.us-west-1.amazonaws.com/presentations/${this.videoid}.pptx`;
            }
        },
        type: function (newVal, oldVal) {
            console.log("type changed", newVal, oldVal, this.type);
            this.getSceneData();
            //refresh video in videoplayer
            if (this.type == "video" && this.videoid.includes('SPTMP')) {
                this.$nextTick(() => {
                    if (this.$refs.videoplayer) {

                        this.$refs.videoplayer.src = `https://sdgenvideos.s3.us-west-1.amazonaws.com/videos/${this.videoid}.mp4#t=0.2`;
                    }
                }
                );
            }
            if (this.type == "pptx") {
                this.pptxurl = `https://sdgenvideos.s3.us-west-1.amazonaws.com/presentations/${this.videoid}.pptx`;
            }
        }
    },


    data() {
        return {
            title: this.videoid,
            showVideoEditor: false,
            querytext: '',
            documenturl: "",
            qinprogress: false,
            isRainbowSpin: false,
            currentoffset: 0,
            showChapters: true,
            scenedata: [],
            videodata: {},
            answeraudio: null,
            visible: false,
            answerimage: "https://sdgenvideos.s3.us-west-1.amazonaws.com/images/invisibleimage.png",
            showanswerimage: false,
            pptxurl: "",
            warnMessage: "",
        }
    },
    methods: {
        redirectToUrl() {
            window.location.href = 'https://app.fictic.ai';
        },
        clickpopquery(event) {
            console.log("clickpopquery", event)
            console.log(this.$refs.popquery)
            this.$refs.popquery.toggle(event);
        },
        toggleRainbowSpin() {
            this.isRainbowSpin = !this.isRainbowSpin;
        },

        changeScene(scene) {
            console.log("change scene", scene, scene.startpos + 1)
            this.$refs.videoplayer.currentTime = parseInt(scene.startpos) + 1;
            console.log("current time is ", this.$refs.videoplayer.currentTime)
        },

        queryVideo() {
            this.answeraudio = null
            amplitude.track('VideoQuestionAsked', { video: this.videoid, question: this.querytext });

            console.log("queryVideo", this.querytext)
            this.qinprogress = true
            this.isRainbowSpin = true
            const videoPlayer = this.$refs.videoplayer;
            if (videoPlayer !== undefined && videoPlayer !== null) {
                console.log("video player is ", videoPlayer)
            if (!videoPlayer.paused) {
                videoPlayer.pause();
                this.currentoffset = videoPlayer.currentTime;


            }
        }
            console.log(this.scenedata)
            this.answerimage = this.scenedata[0].Image
            this.startAnimation();


            fetch('https://qsj5junm5upefdmznlhqweuhey0rbjkr.lambda-url.us-west-1.on.aws/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ videoid: this.videoid, query: this.querytext }),
            })
                .then((response) => response.json())
                .then((data) => {
                    console.log('Success:', data);
                    this.qinprogress = false

                    this.processqueryoutput(data)

                })
                .catch((error) => {
                    this.$refs.popquery.hide();
                    this.qinprogress = false
                    console.error('Error:', error);
                });
        },
        loadSavedVideo(savedvideo) {
            console.log("loadSavedVideo", savedvideo)
            this.showVideoEditor = false;
            if (savedvideo !== undefined) {

                amplitude.track('EditedVideoGenerated', { video: savedvideo });


                // refresh page with new video
                const timestamp = new Date().getTime();
                const newUrl = `${savedvideo}?t=${timestamp}`;
                console.log("new url is ", newUrl)
                this.$refs.videoplayer.src = newUrl
            }


        },

        onAnswerImageLoad() {
            console.log("onAnswerImageLoad")
            if (this.answeraudio != null) {


                var audio = new Audio("data:audio/wav;base64," + this.answeraudio);
                // introduce a 1 second delay
                audio.play();

                audio.addEventListener('ended', () => {
                    this.isRainbowSpin = false;
                    // this.showanswerimage = false;
                    // this.answerimage = "";
                    this.finishAnimation();
                    if (this.currentoffset > 0) {

                        const videoplayer = this.$refs.videoplayer;
                        videoplayer.currentTime = this.currentoffset;
                        // // videoplayer.focus();
                        videoplayer.play();
                    }
                });
            }
        },



        processqueryoutput(data) {
            console.log("processqueryoutput", data)
            this.documenturl = data.documenturl
            console.log(data.answer)
            if (data.image != "") {
                console.log("setting image", data.image)
                this.answerimage = data.image;
                this.startAnimation();
            }
            else {
                this.showanswerimage = false;
                console.log("no image")
                if (data.startpos) {
                    //convert data.startpos to seconds
                    var timetoset = parseInt(data.startpos) + 2
                    console.log("setting time to ", timetoset)
                    this.$refs.videoplayer.currentTime = timetoset;
                    console.log("current time is ", this.$refs.videoplayer.currentTime)
                }
            }

            this.answeraudio = data.audio


            // Decode the base64 string from data.audio and play it



        },
        async getSceneData() {
            var payload = {
                "videoid": this.videoid
            }

            const response = await fetch("https://txevtuio4jeapl6ocpo6w23mcq0ixwkn.lambda-url.us-west-1.on.aws/", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            })
            if (response.ok) {
                this.videodata = await response.json()
                this.scenedata = this.videodata.scenedata
                this.videourl = this.videodata.videourl

                console.log("Scene data", this.scenedata)
            }
            else {
                console.log("Error in fetching scene data")
            }



        },



        async presentVideo(event) {
            try {
                console.log("presentVideo", event)
                const videoElement = this.$refs.videoplayer;
                if (videoElement) {
                    // Request Picture-in-Picture mode
                    await videoElement.requestPictureInPicture();
                    videoElement.play();
                    // Request fullscreen mode
                    if (videoElement.requestFullscreen) {
                        videoElement.requestFullscreen();
                    } else if (videoElement.mozRequestFullScreen) { /* Firefox */
                        videoElement.mozRequestFullScreen();
                    } else if (videoElement.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
                        videoElement.webkitRequestFullscreen();
                    } else if (videoElement.msRequestFullscreen) { /* IE/Edge */
                        videoElement.msRequestFullscreen();
                    }
                }
            } catch (error) {
                console.error("Error presenting video in PiP and fullscreen:", error);
            }
        },
        startAnimation() {
            this.showanswerimage = false; // Reset the state to trigger the animation
            this.$nextTick(() => {
                this.showanswerimage = true; // Set the state to trigger the animation
            });
        },
        finishAnimation() {
            this.showanswerimage = true; // Reset the state to trigger the animation
            this.$nextTick(() => {
                this.showanswerimage = false; // Set the state to trigger the animation
            });
        },
        handleKeydown(event) {
            if (event.ctrlKey && event.key == 'g') {
                console.log("you pressed ", event.ctrlKey, event.key)
                // var videoid=videoimage.split("/").pop().split(".")[0]
                console.log("video id is ", this.videoid)
                this.editVideoId = this.videoid
                this.showVideoEditor = true;
            }
            if (event.key == 'ArrowLeft') {
                console.log("you pressed ", event.ctrlKey, event.key)
                this.showChapters = true;
            }
        }
    }
    ,





    created() {
        console.log("created in ShowVidbyId.vue", this.videoid);
        // amplitude.track('EmbedShareViewed', { adid: this.videoid });

    },
    mounted() {
        const queryParams = this.$route.query;
        console.log("type is ", this.type)
        console.log('Query Parameters:', queryParams);
        console.log('Query Parameters 2:', queryParams, queryParams.mode);
        console.log("queryParams.mode", queryParams.mode, queryParams.mode == "publish")
        if ("mode" in queryParams && queryParams.mode == "publish") {
            console.log("publish video id is ", this.videoid)
            var shareurl = "https://www.fictic.ai/vidid/" + this.videoid + "?type=" + this.type;
            this.warnMessage = "Share link " + shareurl + " copied to clipboard"
            setTimeout(() => {
                this.warnMessage = "";
            }, 3000);

        }
        if ("mode" in queryParams && queryParams.mode == "embed") {
            amplitude.track('EmbedShareViewed', { adid: this.videoid });
        }

        if ("type" in queryParams && queryParams.type == "pptx") {
            console.log("pptx type")
            this.pptxurl = `https://sdgenvideos.s3.us-west-1.amazonaws.com/presentations/${this.videoid}.pptx`;
            

        }
        if (this.type == "pptx") {
            this.pptxurl = `https://sdgenvideos.s3.us-west-1.amazonaws.com/presentations/${this.videoid}.pptx`;
        }

        if (queryParams.edit) {
            console.log("video id is ", this.videoid)
            this.editVideoId = this.videoid
            this.showVideoEditor = true;
        }



        this.getSceneData();
        // const videoPlayer = this.$refs.videoplayer;

        // videoPlayer.addEventListener('loadeddata', () => {
        // //   videoPlayer.play();
        //   if (videoPlayer.requestFullscreen) {
        //     videoPlayer.requestFullscreen();
        //   } else if (videoPlayer.mozRequestFullScreen) { /* Firefox */
        //     videoPlayer.mozRequestFullScreen();
        //   } else if (videoPlayer.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
        //     videoPlayer.webkitRequestFullscreen();
        //   } else if (videoPlayer.msRequestFullscreen) { /* IE/Edge */
        //     videoPlayer.msRequestFullscreen();
        //   }
        // });
        this.$nextTick(() => {
            //   const videoItem = this.$refs.videoplayer;
            window.addEventListener('keydown', this.handleKeydown);


            //   if (videoItem !== undefined) {

            //         videoItem.setAttribute('tabindex', '0'); // Make div focusable
            //         videoItem.addEventListener('keydown', this.handleKeydown);

            //   }

        });
        if (this.$refs.videoplayer !== undefined) {
        this.$refs.videoplayer.addEventListener('loadeddata', () => {
            console.log("Video fully loaded");
            // Simulate AI button click after video is fully loaded
            if (this.$refs.aibtn) {
                this.$refs.aibtn.click();
            }
        });
    }

    },

    beforeUnmount() {
        window.removeEventListener('keydown', this.handleKeydown);


    }



}


</script>
<style scoped>
.videoplayer {
    width: 100%;
    height: 100%;
    max-height: 100%;
    -webkit-touch-callout: none;
    justify-content: center;
    border-radius: 10px;
    border: 0px solid rgb(180, 165, 165);
    /* Set the border color and width */
    box-shadow: 0 0 10px 10px rgba(87, 85, 85, 0.5);
    /* Create the fading effect */




}

.pptplayer {
    width: 70vw;
    max-height: 100%;
    aspect-ratio: 16/9;
    -webkit-touch-callout: none;
    justify-content: center;
    border-radius: 10px;
    border: 0px solid rgb(180, 165, 165);
    /* Set the border color and width */
    box-shadow: 0 0 10px 10px rgba(87, 85, 85, 0.5);
    /* Create the fading effect */




}

.rainbow-spin {
    animation: rainbow-spin 5s linear infinite;
}

@keyframes rainbow-spin {
    0% {
        box-shadow: 0 0 10px 10px red;
    }

    16% {
        box-shadow: 0 0 10px 10px orange;
    }

    33% {
        box-shadow: 0 0 10px 10px yellow;
    }

    50% {
        box-shadow: 0 0 10px 10px green;
    }

    66% {
        box-shadow: 0 0 10px 10px blue;
    }

    83% {
        box-shadow: 0 0 10px 10px indigo;
    }

    100% {
        box-shadow: 0 0 10px 10px violet;
    }
}

.adfooter {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.imageoverlay {
    position: absolute;
    /* top: 0%; */
    left: 0;
    width: 100%;
    aspect-ratio: 16/9;
    max-height: 100%;
    border-radius: 10px;
    /* Match the height of the video player */
    object-fit: cover;
    /* Ensure the image covers the video player area */
    z-index: 1;
    opacity: 0;
    /* Initially hidden */
    transition: opacity 2s ease;
    /* Transition effect */
    filter: pixelate(10px);
    /* Initial pixelated state */
    pointer-events: none;
}

.imageoverlay.visible {
    opacity: 1;
    /* Visible state */
    animation: pixelateIn 3s ease forwards;
    /* Apply pixelated animation */
}

.invitetext {
    color: beige;
    font-size: 1.5em;
    font-weight: bold;
    margin-top: 10px;
}

.imageoverlay.hidden {
    opacity: 0;
    /* Visible state */
    animation: pixelateOut 3s ease forwards;
    /* Apply pixelated animation */
}


@keyframes pixelateIn {
    0% {
        filter: pixelate(10px);
    }

    30% {
        filter: pixelate(7px);
    }

    60% {
        filter: pixelate(4px);
    }

    100% {
        filter: pixelate(0px);
    }
}

@keyframes pixelateOut {
    0% {
        filter: pixelate(0px);
    }

    30% {
        filter: pixelate(3px);
    }

    60% {
        filter: pixelate(7px);
    }

    100% {
        filter: pixelate(10px);
    }
}




.searchgroup {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    /* width: 40%; */
    margin-bottom: 10px;
    width: 100%;
    align-items: center;
}

.queryinput {
    margin-top: 5px;
    width: 100%;
    border-radius: 20px;
    height: 3rem;
}

.searchbtn {
    margin-top: 5px;
    margin-left: 10px;
    border-radius: 20px;
    background: #E76048;
    border: #E76048
}

.logo {
    width: 50px;
    height: auto;
    cursor: pointer
}

.actionspanel {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-top: 30px;
    /* width: 40%; */
    margin-bottom: 30px;
    border: 10px;
    background-color: rgba(30, 30, 27, 0.817);
    border-radius: 30px;
    width: 20vw;
    align-items: center;
}



.queryop {
    width: 60vw;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* Adjust as needed */
}


.video-container {
    position: relative;
    margin-top: 15px;
    background-color: rgb(34, 34, 32, 0);
    display: flex;
}

.info {
    width: 20px;
    height: auto;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 10px;
}

.nonquerybutton {
    width: 50px;
    height: auto;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 10px;
    transition: transform 0.3s ease;
}

.querybutton {
    width: 75px;
    height: auto;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 10px;
    transition: transform 0.3s ease;
}

.querybutton:hover {
    transform: scale(1.2);
}

.nonquerybutton:hover {
    transform: scale(1.1);
}

.poweredby {
    margin-top: 14px;
    color: beige;
}

@media screen and (max-width: 1000px) {
    .videoplayer {
        width: 100%;
        height: 75vh;
        -webkit-touch-callout: none;
        justify-content: center;
    }
    .pptplayer {
        aspect-ratio: 16/9;
        height: 75vh;
        -webkit-touch-callout: none;
        justify-content: center;
    }

}

@media screen and (max-width: 768px) {
    .videoplayer {
        max-width: 100%;
        height: 75vh;
        -webkit-touch-callout: none;
        justify-content: center;
    }
    .pptplayer {
        aspect-ratio: 16/9;
        height: 75vh;
        -webkit-touch-callout: none;
        justify-content: center;
    }

}

.chapterList {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: center;
    margin-top: 10px;
}

.embedbtn {
    width: 30px;
    height: auto;
    cursor: pointer;
}

.chapter {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    background-color: rgb(34, 34, 32);
    margin-top: 10px;
    cursor: pointer;

}

.chapter :hover {
    background-color: rgb(83, 83, 77);


}
</style>