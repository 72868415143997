import { createApp } from 'vue'
import PrimeVue from 'primevue/config';
import App from './App.vue'
import ToastService from 'primevue/toastservice';
import VueGtag from "vue-gtag";
// import { createMetaManager } from 'vue-meta'
import DialogService from 'primevue/dialogservice';

import { createRouter, createWebHistory } from 'vue-router'
import ShowAdbyId from './components/ShowAdbyId.vue';
import ShowVidById from './components/ShowVidById.vue'; 
import ProductInput from './components/ProductInput.vue';
import dynamicAds from './components/dynamicAds.vue';
import ProcessPurchase from './components/ProcessPurchase.vue';
import Aura from '@primevue/themes/aura';
import { definePreset } from '@primevue/themes';



import 'bootstrap/dist/css/bootstrap.min.css';
import IQFrame from './components/IQFrame.vue';
import Tooltip from 'primevue/tooltip';
import vue3GoogleLogin from 'vue3-google-login'


// import Lara from '@/presets/lara';
// import Wind from '@/presets/wind';

// import { createGtm } from '@gtm-support/vue-gtm';
// import router from './router';



const app= createApp(App);
const routes = [
  { path: '/', component: ProductInput},
  { path: '/adid/:adid', component: ShowAdbyId, props: true },
  { path: '/vidid/:videoid', component: ShowVidById,props: route => ({ videoid: route.params.videoid, type: route.query.type }) },
  { path: '/share/:videoid', component: IQFrame, props: route => ({ videoid: route.params.videoid, type: route.query.type }) },
  { path: '/dynamic/:productId', component: dynamicAds, props: true },
  { path: '/process', component: ProcessPurchase, props: true }
];
const router = createRouter({
  history: createWebHistory(),
  routes,
});

console.log("Router created",router.getRoutes())
app.use(router);
const MyPreset = definePreset(Aura, {    
components: {
  menubar: {
      colorScheme: {
          light: {
              root: {
                  background: '#fff',
                  color: '#121212'
              },
              subtitle: {
                  color: '#121212'
              }
          },
          dark: {
              root: {
                  background: '#121212',
                  color: '#fff'
              },
              subtitle: {
                  color: '#fff'
              }
          }
      }
  },
  panel: {
    colorScheme: {
        light: {
            root: {
                background: 'inherit',
                color: '#121212'
            },
            subtitle: {
                color: '#121212'
            }
        },
        dark: {
            root: {
                background: 'inherit',
                color: '#fff'
            },
            subtitle: {
                color: '#fff'
            }
        }
    }
},
dialog: {
  colorScheme: {
      light: {
          root: {
              background: '#fff',
              color: '#121212'
          },
          subtitle: {
              color: '#121212'
          }
      },
      dark: {
          root: {
              background: '#121212',
              color: '#fff'
          },
          subtitle: {
              color: '#fff'
          }
      }
  }
},
drawer: {
colorScheme: {
    light: {
        root: {
            background: '#fff',
            color: '#121212'
        },
        subtitle: {
            color: '#121212'
        }
    },
    dark: {
        root: {
            background: '#121212',
            color: '#fff'
        },
        subtitle: {
            color: '#fff'
        }
    }
}
},
fileUpload: {
  colorScheme: {
      light: {
          'background': '#fff',
          '--p-fileupload-border-color': '#cccccc',
          '--p-fileupload-color': '#000000',
      },
      dark: {
          'background': '#121212',
          '--p-fileupload-border-color': '#cccccc',
          '--p-fileupload-color': '#000000',
      }
  }
}
}
});

app.use(PrimeVue, {
inputStyle: "filled",
theme: {
  preset: MyPreset,
  options: {
    darkModeSelector:  '.my-app-dark',
    prefix: 'p',
    cssLayer: false,
  },
},
});
app.use(ToastService);
app.use(DialogService);
app.directive('tooltip', Tooltip);




app.use(VueGtag, {
    config: { id: "GT-NGW6LZPT" }
  })

  app.use(vue3GoogleLogin, {
  clientId: '352542119310-0mt35eq251formq58g8hln5qjei1iv40.apps.googleusercontent.com',
  redirectUri: 'http://localhost:8080',
    scope: 'email profile',
})

router.isReady().then(() => app.mount('#app'))
app.directive('tooltip', Tooltip);
