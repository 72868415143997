<template>

<Header v-if="!(this.$route.path.includes('adid') || this.$route.path.includes('share'))" :jobcounter="this.jobcounter" :apptokensLeft=this.tokensLeft :emailsubmission="this.emailsubmission" @selectProduct="this.onSelectProduct" @selectJob="this.onSelectJob" @showPricing="showPricingChange" @adgenieuseridFound="this.updateUser" @tokensLeftChange="this.changeTokens"/>
  <div class="container">

  <router-view msg="Fictic: AI generated videos for your business" :adgenieuserid=this.adgenieuserid :productMode="this.productMode" :selectedjobid="this.jobid" :tokensLeft="this.tokensLeft" @incrementjob="this.incrementjob" @emailsubmitted="this.emailsubmission=true"  :showPricingDialog="this.showPricingDialog" @deducttoken="this.deductTokens" />
  <Footer v-if="!(this.$route.path.includes('adid')  || this.$route.path.includes('share')) " />
  </div>
</template>

<script>
import Header from './components/Header.vue';
// import ProductInput from './components/ProductInput.vue';
import Footer from './components/Footer.vue';
import * as amplitude from '@amplitude/analytics-browser';
// import { useMeta } from 'vue-meta'






export default {
  name: 'App',
  data: () => ({
    jobid: "",
    tokensLeft: 0,
    jobcounter:0,
    showPricingDialog: null,
    emailsubmission:false,
    adgenieuserid: null,
    productMode: "SocialMedia"
  }),
  components: {
    Header,
    Footer
  },
  metaInfo: {
      title: 'Default Title',
      titleTemplate: '%s | My Awesome Webapp'
    }
  ,
  methods: {
    onSelectJob(jobid) {
      console.log("emit received in app vue",jobid);
      this.jobid=jobid;
    },
    onSelectProduct(productMode) {
      console.log("emit received in app vue",productMode);
      this.productMode=productMode;
    },
    changeTokens(tokens) {
      console.log("tokens left updated in app vue ",tokens);
      this.tokensLeft = tokens;
    },
    incrementjob(jobcounter){
      console.log("increment job in app vue",jobcounter,this.jobcounter);
      this.jobcounter++;
    },
    showPricingChange(val) {
      this.showPricingDialog = val;
    },
    deductTokens(tokens) {
      console.log("deducting tokens in app vue ",tokens)
      this.tokensLeft -= tokens;
    },
    updateUser(userid){
      console.log("user id received in app vue",userid);
      this.adgenieuserid=userid;
    }

  },
  mounted() {
    console.log(this.$route.path)
    amplitude.init('c64ef8bc181f02c274222d1fc4243907',{defaultTracking: true});

  }
}
</script>

<style>

html {
  height: 100vh;
  margin: 0;
  background-attachment: fixed;
}
body {
  background-color: #f5f5f5;
  color: #121212;
}
.my-app-dark body {
  background-color: #121212;
  color: #f5f5f5;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
.icon{
  width:30px;
  height:30px;
  display:inline-block;
}

.p-menubar-root-list{
  padding:0 !important;
  margin:0 !important;
}
.p-menubar-root-list li{
  margin:0 10px;
}
.p-menubar-root-list li a{
  text-decoration:none;
}
.p-menubar svg{
  width:35px;
}
.p-menubar-mobile-active{
  position:unset;
}

.p-dialog{
  border-radius:30px;
  border:none;
}
.p-dialog .p-dialog-header{
  background:transparent;
}
 .p-dialog .p-dialog-content{
  background:transparent;
}


 .p-dialog-header{
   text-align:center;
   display:block;
   padding-top:20px

 }
 .p-dialog-header span{
   text-align:center;
   display:block;
   font-family: Avenir, Helvetica, Arial, sans-serif;

 }
 .p-dialog-header-icons{
   position: absolute;
   top: 20px;
   right: 20px;
   z-index: 100;
 }
 .p-progressbar{
   border-radius:20px;
   margin-bottom:40px;
 }
.p-progressbar .p-progressbar-value{
  background-image:linear-gradient(to right, #F35B0F, #F3940F);
}
.p-button.p-fileupload-choose{
  background: #1D66F3;
  border: none;
  border-radius: 10px;
}
.p-progress-spinner{
  margin-top:0 !important;
}
.p-message{
  width:100%;
  text-align:left;
}
.p-message-wrapper{
  padding:0 10px;
  width:100%;
}
.p-message-wrapper p{
  margin-bottom:0;
}
.p-message-close{
  margin-left:auto;
}

@media(max-width:960px) {
  .p-menubar-root-list{
    width: 100%;
    top: 75px;
    left: 0;
    height: 100%;
    padding:30px 15px !important;
  }

  .p-menubar-root-list li{
    margin: 15px 0 !important;
  }

}

@media(max-width:768px) {

  .p-dialog{
    width:85% !important;
    height:85vh !important;
  }
  
  .rightpanel{
    width: 100% !important;
    float: none !important;
    margin-bottom: 15px !important;
  }
  .iptext, .urltext, .bigtext{
    width:100% !important;
  }
}
</style>
