<template>

  <div class="header">
    <!-- <img class="logo" src="../assets/AdGenie_logo.png" alt="logo" /> -->
      <!-- <img class="mobile_logo" src="../assets/AdGenie_logo.png" alt="logo" /> -->

    <!-- <img class="logotext" src="../assets/adgenietext.png" alt="logo"/>" -->


    <!-- <div class="tags tags_mobile"> -->

    <!-- <Tag value="Limited Preview" class="betatag" severity="danger" rounded></Tag> -->

    <!-- <div class="tokeninfo" @click="showPricing">
        <div class="tokentext"> {{ this.tokensLeft }} </div>

        <svg class="icon" data-slot="icon" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
          <path stroke-linecap="round" stroke-linejoin="round"
            d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125">
          </path>
        </svg>
      </div> -->
    <!-- </div> -->

    <Menubar :model="items">
      <template #start>
        <a href="/">
          <img v-if="themeMode=='light'"  class="light_logo" src="../assets/ficticlogo.png" alt="logo"  />
          <img v-else class="logo" src="../assets/ficticlogo.png" alt="logo" />

      <img class="mobile_logo" src="../assets/ficticlogo.png" alt="logo" /> </a>
      </template>
      <template #item="{ item, props, hasSubmenu, root }">
        <a class="flex align-items-center" v-bind="props.action">
          <span :class="item.icon" />
          <span class="ml-2">{{ item.label }}</span>
          <!-- <Badge v-if="item.badge" :class="{ 'ml-auto': !root, 'ml-2': root }" :value="item.badge" /> -->
          <span v-if="item.shortcut" class="ml-auto border-1 surface-border border-round surface-100 text-xs p-1">{{
            item.shortcut }}</span>
          <i v-if="hasSubmenu"
            :class="['pi pi-angle-down', { 'pi-angle-down ml-2': root, 'pi-angle-right ml-auto': !root }]"></i>
        </a>
      </template>
      <template #end>
        <template style="display: flex; align-items: center; justify-content: space-between; gap: 10px;">
              <div @click="toggleTheme" class="theme-toggle" v-tooltip.bottom="'Click Here To Change Theme...'">
      {{ themeMode ==='light' ? '🌙' : '🔆' }}
    </div>
              <div class="flex items-center gap-2">
                <GoogleLogin v-if="userdet == null" :callback="logincomplete"  >
                  <button @click="loginaction"   class="login_btn">Sign in </button>
                </GoogleLogin>
                <img v-if="this.userdet !== undefined && this.userdet !== null && this.userdet.picture !== undefined && userdet.picture !== null" :src="userdet.picture" class="profilephoto" alt="profile" v-tooltip.bottom="this.userdet.name" />
                </div>
                

             </template>
      </template>
    </Menubar>

    <!-- <div class="tags"> -->
    <!--
          <Tag value="Limited Preview" class="betatag" severity="danger" rounded></Tag>
      -->
    <!-- <div class="tokeninfo" @click="showPricing">
        <div class="tokentext"> {{ this.tokensLeft }} </div>

        <svg class="icon" data-slot="icon" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
          <path stroke-linecap="round" stroke-linejoin="round"
            d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125">
          </path>
        </svg>
      </div> -->
    <!-- </div> -->
  </div>

</template>

<script>
import Menubar from 'primevue/menubar';
/*import Tag from 'primevue/tag';*/
import { event } from 'vue-gtag';
import * as amplitude from '@amplitude/analytics-browser';
// import { googleOneTap } from "vue3-google-login"



export default {
  name: 'HeaderComp', // replace with your component name
  data() {
    return {
      items: [],
      jobs: [],
      tokensLeft: 0,
      products: [],
      adgenieuserid: "",
      userdet: null,
      themeMode:localStorage.getItem('theme') || 'light'




    };
  },
  components: {
    Menubar
  },
  emits: ["selectJob", "tokensLeftChange", "showPricing", "adgenieuseridFound", "selectProduct"],
  props: ["jobcounter", "emailsubmission", "apptokensLeft"],
  watch: {
    jobcounter: {
      handler(val) {
        console.log("total jobs changed", val);
        this.fetchOldJobs();
      }
    },
    apptokensLeft: {
      handler(val) {
        console.log("tokens left changed in header", val);
        this.tokensLeft = val;
      }
    },
    emailsubmission: {
      handler(val) {
        console.log("email submitted in header", val);
        amplitude.track('AwardedTokens', 50);
        fetch("https://tv2fr6ulib.execute-api.us-west-1.amazonaws.com/prod/addtoken?tokenstoadd=50").then(response => {
          console.log(response);
          this.fetchOldJobs();
        });
      }
    }
  },

  methods: {
    toggleTheme() {
    const isDark = document.documentElement.classList.toggle('my-app-dark');
    this.themeMode = isDark ? 'dark' : 'light'; 
    localStorage.setItem('theme', this.themeMode);
  },
    async fetchOldJobs(onmount = false) {
      console.count("Fetching old jobs");
      const url = new URL("https://tv2fr6ulib.execute-api.us-west-1.amazonaws.com/prod/userinfo");

      // Create a URLSearchParams object
      const params = new URLSearchParams();
      if (this.adgenieuserid != null && this.adgenieuserid != "") {
        console.log("userid found", this.adgenieuserid)
        params.append('userid', this.adgenieuserid);
      }
      else {
        console.log("No userid found")

      }


      // Append the parameters to the URL
      url.search = params;
      const response = (await fetch(url).then(response => response.json())
        .then(data => {
          this.jobs = data.jobs;
          this.tokensLeft = data.tokensLeft;
          this.$emit('tokensLeftChange', data.tokensLeft);
          this.setCookie('adgenieuserid', data.userid, 365);
          console.log(data);
          console.log(data.tokensLeft)
          var prodlist = this.jobs.map(job => ({ label: job.product, key: job.jobid, command: this.selectJob }));
          console.log(this.prodlist);
          // this.products=prodlist.map(label => ({ label: label }));
          this.items[2].items = prodlist;
          if (onmount == true && prodlist.length > 0) {
            console.log("PRODUCT ITEM",this.items[2].label)
            this.items[2].label = "My Videos"+ " (" + prodlist.length.toString() + ")";
            console.log("selecting job", prodlist[0].key)
            //this.clickAssetsItem();
            
            // this.$emit('selectJob', prodlist[0].key);

            //   if (!(document.referrer.includes("fictic") || window.location.href.includes("fictic"))){
            // this.$emit('selectJob', prodlist[0].key);
            // }
          }
          // this.items[4]



          return data;
        }));
      console.log(response);

    },

    logincomplete(response){
      console.log("Login complete", response)
      console.log("response code is ",response.code)
      console.log ("getting user data")
      
      
      var oauthurl="https://bses26hxi2gme34243zm25cspa0zhuuz.lambda-url.us-west-1.on.aws/"
      fetch(oauthurl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ "code": response.code }),
      })
        .then(response => response.json())
        .then(data => {
          console.log('Success:', data);
          try {
            this.userdet = JSON.parse(data.body)
          }
          catch (e) {
            console.log("Error parsing user data", e)
          }
          console.log("user details from webservice is",this.userdet)
          var jsondata=JSON.stringify(this.userdet)
          this.setCookie('userdet', jsondata, 365);
          this.adgenieuserid=this.userdet.id
          this.setCookie('adgenieuserid', this.adgenieuserid, 365);
          this.$emit('adgenieuseridFound', this.adgenieuserid);
          this.fetchOldJobs(true);
          amplitude.track('LoginComplete', { "User": this.userdet.name , "Email": this.userdet.email, "UserId": this.userdet.id});
          

          
        })
        .catch((error) => {
          console.error('Error:', error);
        });
      
    },

    showPricing() {
      console.log("Show Pricing")
      amplitude.track('TokenClicked');
      event('PricingClicked');
      // this.$router.push('/pricing');
      this.showPricingDialog = Math.floor(new Date().getTime() / 1000);
      this.$emit('showPricing', this.showPricingDialog);

    },
    getCookie(name) {
      console.log("getting cookie", document.cookie)
      const value = "; " + document.cookie;
      const parts = value.split("; " + name + "=");
      if (parts.length === 2) return parts.pop().split(";").shift();
    },
    setCookie(name, value, days) {
      let expires = "";
      if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
      }
      document.cookie = name + "=" + (value || "") + expires + "; path=/";
    },

    selectJob(events) {
      amplitude.track('PreviousJobSelected', { "selectedjob": events.item.key });
      event('PreviousJobSelected', { "selectedjob": events.item.key });
      console.log("selectedJob is", events.item.key);
      this.$emit('selectJob', events.item.key);
    },

    clickAssetsItem() {
      const assetsItem = this.items.find(item => item.label === "My Videos");
      console.log(assetsItem);
      console.log(assetsItem.$el);

      assetsItem.$el.click();
      if (assetsItem && assetsItem.command) {
          assetsItem.command();
      }
    }

    // Your methods go here
  },
  provide() {
    // use function syntax so that we can access `this`
    return {
      themeMode: this.themeMode
    }
  },
  created() {
    this.toggleTheme();
    
    console.log("adgenieuserid is ", this.adgenieuserid);
    console.log("userdet is ", this.userdet);
    var userdetail=this.getCookie('userdet')
    console.log("userdetail is ", userdetail);
    if (userdetail!=null){
      this.userdet = JSON.parse(userdetail);
      this.adgenieuserid = this.userdet.id;
    }
    else
    {
      this.adgenieuserid = this.getCookie('adgenieuserid')
    }
    
    if (this.adgenieuserid != null) {
      this.$emit('adgenieuseridFound', this.adgenieuserid);
    }

    console.log("adgenieuserid is ", this.adgenieuserid);
    var docproduct = { "label": "Doc To Video", command: () => { amplitude.track('Product Chosen', { "Product": "Doc To Video" }); this.$emit('selectProduct', "DocToVideo"); } }
    var vidproduct = { "label": "Promo Videos", command: () => { amplitude.track('Product Chosen', { "Product": "Promo Videos" }); this.$emit('selectProduct', "VideoPromos"); } }
    var vidads = { "label": "Video Ads", command: () => { amplitude.track('Product Chosen', { "Product": "Video Ads" }); this.$emit('selectProduct', "VideoAds"); } }
    var imageads = { "label": "Image Ads", command: () => { amplitude.track('Product Chosen', { "Product": "Image Ads" }); this.$emit('selectProduct', "ImageAds"); } }
    // var availablesoon = { "label": "Coming Soon" }
    var homeitem = { "label": "Home", "to": "/" }
    var productitem = { "label": "Products", "to": "/product", "items": [docproduct, vidproduct, vidads, imageads], "hasSubmenu": true, }
    // var pricingitem = { "label": "PricinDg", "hasSubmenu": false, "command": this.showPricing }
    // var signupitem = { "label": "Signup", "to": "/signup", "items": [availablesoon], "hasSubmenu": true }
    var assetsitem = { "label": "My Videos", "to": "/login", "items": [], "hasSubmenu": true, "command": () => { amplitude.track('MyVideosClicked'); } }

    this.items = [homeitem, productitem, assetsitem]
  },

  mounted() {
    // googleOneTap()
    // .then((response) => {
    //   // This promise is resolved when user selects an account from the the One Tap prompt
    //   console.log("Handle the response", response)
    //   var token=response.credential
    //   var headers={
    //     'Authorization': 'Bearer ' + token,
    //     'Content-Type': 'application/json'
    //   }

    //   fetch("https://www.googleapis.com/oauth2/v3/userinfo", { method: 'GET', headers: headers}).then(response => response.json())
    //     .then(data => {
    //       console.log('Success:', data);
          
    //     })
    //     .catch((error) => {
    //       console.error('Error:', error);
    //     });
    // })
    // .catch((error) => {
    //   console.log("Handle the error", error)
    // })

    if (this.adgenieuserid != null) {
      this.fetchOldJobs(true);
    }
    else {
      console.log("No userid found")
    }
  },
  computed: {
    currentTheme() {
      return this.themeMode === 'dark' ? 'dark' : 'light';
    },
  }




};
</script>

<style scoped>
.logo {
  margin-right: auto;
  height: 50px;
  width: 100px;
}
.theme-toggle{
  cursor: pointer;
}
.light_logo{
 filter: invert(1);
 margin-right: auto;
  height: 50px;
  width: 100px;
}
.signup_btn{
  background:#E76408;
  border:none;
  padding: 8px 15px;
    border-radius: 6px;
    color: white;
    font-size: 14px;
    font-weight: 600;
}
.login_btn{
  background:#fff;
  border:0.5px solid #d0d0d0;
  padding: 8px 15px;
    border-radius: 6px;
    color: #E76408;
    font-size: 14px;
    font-weight: 600;
}
.mobile_logo {
  margin-right: auto;
  display: none;
  height: 50px;
  width: 100px;
}

.tags_mobile {
  display: none;
}


.p-menubar {
  border: none;
}

.logotext {
  display: inline;
  position: absolute;
  justify-content: center;
  align-items: center;
  left: 2rem;
  color: rgb(241, 158, 56);
  top: 5.5rem;
  font-weight: 800;
  height: 30px;
  width: 100px;
}

.header {
  /* padding: 0px 32px; */
  position: sticky;
 top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  border-bottom: 1px solid transparent;
  box-shadow: 0px 4px 6px rgba(128, 128, 128, 0.08);


}

.tags {
  margin-left: auto;

}

.tokeninfo {
  cursor: pointer;

}

.tokeninfo svg {
  cursor: pointer;
  color: #FFBF00;

}
.profilephoto {
  border-radius: 50%;
  height: 30px;
  width: 30px;
  margin-right: 1rem;
}

.tokentext {
  position: relative;
  justify-content: center;
  font-size: large;
  margin-right: 0.5em;
  display: inline-block;

}

.betatag {
  position: relative;
  font-size: large;
}



@media(max-width:960px) {

  .logo,
  .tags {
    display: none;
  }

  .mobile_logo,
  .tags.tags_mobile {
    display: block;
  }

  .tags.tags_mobile {
    margin-left: auto;
    margin-right: auto;
  }

  .p-menubar {
    margin-left: auto;
  }
}

/* Your CSS goes here */
</style>
